
.pdf-file-item:hover {
    background: rgba(0, 0, 0, 0.05);
}

.pdf-file-item {
    transition: background-color 0.2s;
}

.pdf-file-name {
    width: 200px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
    text-align: left;
}